<template>
  <div class="main-div">
    <PageBack />
    <div class="common set">
      <h2 class="page-title">设置</h2>
      <div class="page-content">
        <div class="set-list">
          <div class="set-title collapse" @click="handleArrow">
            <p class="set-p">
              <img
                src="@/assets/images/set/modify.png"
                alt=""
                class="set-icon"
              />
              <span class="set-span">修改密码</span>
            </p>
            <img
              src="@/assets/images/common/arrow.png"
              alt=""
              class="set-arrow"
            />
          </div>
          <div class="set-item form">
            <div class="input">
              <div class="input-content">
                <i class="phone"></i>
                <input
                  type="text"
                  placeholder="请输入手机号"
                  form="rules"
                  v-model="mobile"
                  readonly
                />
              </div>
            </div>
            <div class="input">
              <div class="input-content">
                <i class="code"></i>
                <input
                  type="text"
                  placeholder="请输入验证码"
                  form="rules"
                  v-model="passForm.code"
                  v-checkParam="rules.code"
                />
                <a class="btn-send" @click="handleSendCode">{{ codeText }}</a>
              </div>
            </div>
            <div class="input">
              <div class="input-content">
                <i class="pass"></i>
                <input
                  type="password"
                  placeholder="请输入新密码"
                  form="rules"
                  v-model="passForm.pwd"
                  v-checkParam="rules.pwd"
                />
              </div>
            </div>
            <div class="input">
              <div class="input-content">
                <i class="pass"></i>
                <input
                  type="password"
                  placeholder="请确认新密码"
                  form="rules"
                  v-model="passForm.rePwd"
                  v-checkParam="rules.rePwd"
                />
              </div>
            </div>
            <button
              class="button pass-btn"
              v-checkSubmit="'rules'"
              :submit="handleModifyPass"
            >
              确认修改
            </button>
          </div>
        </div>
        <div class="set-list">
          <div class="set-title collapse" @click="handleArrow">
            <p class="set-p">
              <img src="@/assets/images/set/show.png" alt="" class="set-icon" />
              <span class="set-span">显示设置</span>
            </p>
            <img
              src="@/assets/images/common/arrow.png"
              alt=""
              class="set-arrow"
            />
          </div>
          <div class="set-item">
            <div class="color">
              <p class="p-title">涨跌色显示</p>
              <p class="p-title form">
                <label class="radio" @click="handleColorSet(1)">
                  <span
                    class="input-radio"
                    :class="{ 'is-checked': colorSet == 1 }"
                  >
                    <span class="radio-inner"></span>
                    <input type="radio" />
                  </span>
                  <span class="radio-label">红涨绿跌</span>
                </label>
                <label class="radio" @click="handleColorSet(2)">
                  <span
                    class="input-radio"
                    :class="{ 'is-checked': colorSet == 2 }"
                  >
                    <span class="radio-inner"></span>
                    <input type="radio" />
                  </span>
                  <span class="radio-label">红跌绿涨</span>
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import account from "@/apis/account.js";
import { collapse } from "@/mixins/collapse.js";
import PageBack from "@/components/pageBack.vue";
export default {
  name: "Bank",
  mixins: [collapse],
  components: {
    PageBack,
  },
  data() {
    let checkRePass = (value, callback) => {
      if (!value) {
        callback("请确认新密码");
      } else if (value != this.passForm.pwd) {
        callback("确认密码和新密码不一致");
      }
    };
    return {
      mobile: "",
      passForm: {
        sendType: 1,
        code: "",
        pwd: "",
        rePwd: "",
      },
      rules: {
        code: [{ rule: "required", msg: "请输入验证码" }],
        oldPass: [{ rule: "required", msg: "请输入旧密码" }],
        pwd: [
          { rule: "required", msg: "请输入新密码" },
          {
            rule: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
            msg: "密码为6-12位字母加数字",
          },
        ],
        rePwd: [checkRePass],
      },
      timer: "", //验证码定时器
      codeText: "获取验证码",
      isShow: false, //是否显示
      colorSet: "",
    };
  },
  methods: {
    handleBack() {
      this.$router.goBack();
    },
    handleArrow(e) {
      this.handleCollapse(e);
    },
    handleSendCode() {
      if (!this.mobile) {
        this.$toast("请先绑定手机");
        return;
      }
      if (this.codeText.indexOf("秒") > -1) {
        return;
      }
      this.$toast.loading({
        overlay: true,
        message: "正在发送...",
        duration: 0,
      });
      let params = {
        sendType: this.passForm.sendType, //发送类型（1：手机 2：邮箱）
      };
      account.sendPhoneCode(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.$toast(res.Data);
          this.countNum();
        } else {
          this.$toast(res.Data);
        }
      });
    },
    //发送验证码倒计时
    countNum() {
      clearInterval(this.timer);
      let count = 60;
      let text = "";
      this.timer = setInterval(() => {
        if (count <= 0) {
          count = 0;
          text = "重新获取验证码";
          clearInterval(this.timer);
        } else {
          count -= 1;
          text = count + "秒后重新获取";
        }
        this.codeText = text;
      }, 1000);
    },
    handleModifyPass() {
      const params = {
        pwd: this.passForm.pwd,
        sendType: this.passForm.sendType,
        code: this.passForm.code,
      };
      this.$toast.loading({
        overlay: true,
        message: "修改中...",
        duration: 0,
      });
      account.changePwd(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.$toast.success("恭喜您，密码修改成功");
          setTimeout(() => {
            //清除用户信息 重新登录
            localStorage.removeItem("token");
            localStorage.removeItem("userType"); //真实or模拟用户 1真实 0模拟
            localStorage.removeItem("userInfo");
            //同步store
            this.$store.commit("login/setToken", "");
            this.$store.commit("login/setUserType", "");
            this.$store.commit("login/setUserInfo", "");
            this.$router.push({
              name: "index",
            });
          }, 1000);
        } else {
          this.$toast(res.Data);
        }
      });
    },
    handleColorSet(val) {
      //找到html标签
      var htmlNode = document.documentElement;
      //读取html标签(根标签)的CSS变量的值
      var upColor = htmlNode.style.getPropertyValue("--up-color");
      //var upColor=getComputedStyle(htmlNode)['--up-color'];
      if (val == 1) {
        //红涨绿跌
        if (upColor == this.$store.state.common.redColor) {
          return;
        } else {
          this.colorSet = 1;
          this.setColor(
            this.$store.state.common.redColor,
            this.$store.state.common.greenColor,
            htmlNode,
            "红涨绿跌"
          );
        }
      } else {
        //红跌绿涨
        if (upColor == this.$store.state.common.greenColor) {
          return;
        } else {
          this.colorSet = 2;
          this.setColor(
            this.$store.state.common.greenColor,
            this.$store.state.common.redColor,
            htmlNode,
            "红跌绿涨"
          );
        }
      }
    },
    setColor(upColor, downColor, htmlNode, text) {
      //将html标签(根标签)的CSS变量的值修改
      htmlNode.style.setProperty("--up-color", upColor);
      htmlNode.style.setProperty("--down-color", downColor);
      //存储到localstorage
      let colorSet = {
        upColor: upColor,
        downColor: downColor,
        text: text,
      };
      localStorage.setItem("colorSet", JSON.stringify(colorSet));
      this.$store.commit("common/setColorSet", colorSet);
    },
  },
  mounted() {
    if (this.$store.state.common.colorSet.text == "红涨绿跌") {
      this.colorSet = 1;
    } else {
      this.colorSet = 2;
    }
    this.mobile = this.$store.state.login.userInfo
      ? this.$store.state.login.userInfo.mobile
      : "";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/setting/set.scss";
</style>
